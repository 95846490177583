import * as React from "react"
import { Link } from "gatsby"
import LayoutEn from "../../components/layoutEn"

const SuccessPage = () => {
  return (
    <LayoutEn pageTitle="Success">
      <div className="nomal-page">
        <h1>Thank you.</h1>
        <p>
          <br />
          Your message has been sent successfully.
          <br />
          <br />
          <br />
          <br />
          <Link to="/en" className="underline">Go home</Link>.<br/>
        </p>
      </div>
    </LayoutEn>
  )
}

export default SuccessPage
